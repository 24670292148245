import React, { useEffect, useState } from "react"
import Firebase from "./firebase"
import useConsent from "../consent"

const FirebaseContext = React.createContext(null)

const useFirebase = () => React.useContext(FirebaseContext)

export const FirebaseProvider = props => {
  let consent = useConsent()
  console.debug("consent", consent)
  const [firebase, setFirebase] = useState(undefined)

  useEffect(() => {
    setFirebase(new Firebase(consent))
  }, [])

  useEffect(() => {
    if (consent !== undefined) {
      firebase.then(firebase => {
        firebase.setCookieConsent(consent)
      })
    }
  }, [consent, firebase])

  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  )
}

export default useFirebase
