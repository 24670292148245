import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

function Banner({ setConsent }) {
  const handleAccept = () => {
    setConsent(true)
  }
  return (
    <StyledBanner>
      <BannerContent>
        <BannerTitle>This website uses Cookies</BannerTitle>
        <BannerText>
          If you click on "accept cookies", you agree to the storage of cookies
          on your device. We use cookies to improve site navigation and analyzie
          site usage. For detailed information see:{" "}
          <Link to={"/dataSecurity"}>Privacy Policy</Link>
        </BannerText>
      </BannerContent>
      <BannerActions>
        <ConsentButton onClick={handleAccept}>Accept Cookies</ConsentButton>
      </BannerActions>
    </StyledBanner>
  )
}

const BannerTitle = styled.h5`
  margin: 10px 0px;
  ${props => props.theme.font_size.regular}
`

const BannerContent = styled.div`
  padding: 4px 10px;
`

const BannerActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin 5px 10px;
`

const StyledBanner = styled.div`
  position: fixed;
  width: 100%;
  z-index: 11;
  padding: 8px 4px 8px 4px;
  bottom: 0;
  background-color: ${props => props.theme.color.white.dark};
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  box-shadow: rgba(0, 0, 0, 0.2) 0px -2px 10px 0px;
  @media (max-width: ${props => props.theme.screen.xs}) {
    flex-direction: column;
    padding: 5px 10px;
  }
`

const BannerText = styled.div`
  align-items: center;
  ${props => props.theme.font_size.xsmall}
`

const ConsentButton = styled.button`
  color: ${props => props.theme.color.primary.contrastText};
  background-color: ${props => props.theme.color.primary.main};
  transition: background-color 1.5s ease-out;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  height: 40px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  border-radius: 4px;
  padding: 0px 20px;
  border-image: initial;
  outline: 0px;
  &:hover {
    background: ${props => props.theme.color.primary.dark};
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

export default Banner
