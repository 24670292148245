// Theme

const theme = {
  font: {
    primary: `font-family: 'Roboto'; font-weight: 400;`,
    secondary: `font-family: 'Roboto'; font-weight: 500;`,
    light: `font-family: 'Roboto'; font-weight: 300;`,
    normal: `font-family: 'Roboto'; font-weight: 400;`,
    medium: `font-family: 'Roboto'; font-weight: 500;`,
    thin: `font-family: 'Roboto'; font-weight: 100;`,
    bold: `font-family: 'Roboto'; font-weight: 700;`,
    extrabold: `font-family: 'Roboto'; font-weight: 900;`,
  },
  font_size: {
    xxxsmall: "font-size: 12px;",
    xxsmall: "font-size: 14px;",
    xsmall: "font-size: 16px;",
    small: "font-size: 17px;",
    regular: "font-size: 22px; line-height: 30px;",
    large: "font-size: 28px; line-height: 30px;",
    larger: "font-size: 40px; line-height: 50px;",
    xlarge: "font-size: 48px; line-height: 48px;",
  },
  color: {
    warning: {
      main: "#c62828",
      contrastText: "#fff",
    },
    danger: {
      main: "#d84315",
      contrastText: "#fff",
    },
    success: {
      main: "#43a047",
      contrastText: "#fff",
    },
    primary: {
      verylight: "rgba(13, 103, 88, 0.2)",
      light: "#48a999",
      main: "#00796b",
      dark: "#004c40",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ffd149",
      main: "#ffa000",
      dark: "#c67100",
      contrastText: "#000",
    },
    accent: "#cca86e",
    background: {
      white: "#ffffff",
      light: "#f8f8f8",
    },
    white: {
      regular: "#ffffff",
      lessdark: "#faf9f8",
      dark: "#f6f6f6",
      darker: "#cecece",
    },
    black: {
      lightest: "#666",
      light: "#564F62",
      regular: "#071435",
    },
  },
  screen: {
    xs: "575px",
    sm: "767px",
    md: "991px",
    lg: "1199px",
  },
}

export default theme
