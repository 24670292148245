import app from "firebase/app"
import "firebase/analytics"

const getCredentials = async () => {
  let credentials = undefined
  if (process.env.NODE_ENV === "development") {
    if (
      !process.env.GATSBY_FIREBASE_API_KEY ||
      !process.env.GATSBY_FIREBASE_APP_ID
    ) {
      console.error(
        "no api credentials found, please provide them via .env file"
      )
    } else {
      credentials = await new Promise(resolve => {
        setTimeout(() => {
          resolve({
            apiKey: process.env.GATSBY_FIREBASE_API_KEY,
            authDomain: process.env.GATSBY_FIREBASE_AUTH_DOMAIN,
            databaseURL: process.env.GATSBY_FIREBASE_DATABASE_URL,
            projectId: process.env.GATSBY_FIREBASE_PROJECT_ID,
            storageBucket: process.env.GATSBY_FIREBASE_STORAGE_BUCKET,
            messagingSenderId: process.env.GATSBY_FIREBASE_MESSAGING_SENDER_ID,
            appId: process.env.GATSBY_FIREBASE_APP_ID,
            measurementId: process.env.GATSBY_FIREBASE_MEASUREMENT_ID,
          })
        }, 1)
      })
    }
  } else {
    credentials = await fetch("/__/firebase/init.json").then(r => r.json())
  }
  return credentials
}

let firebaseInstance

const getFirebaseInstance = cookieConsent => {
  if (!firebaseInstance) {
    console.debug(`firebase instance undefined, creating instance now`)
    firebaseInstance = new Firebase(cookieConsent)
  }
  return firebaseInstance
}

class Firebase {
  constructor(cookieConsent) {
    return new Promise(async (resolve, reject) => {
      this.credentials = await getCredentials()
      this.firebase = app
      this.firebase.initializeApp(this.credentials)
      this.cookieConsent = cookieConsent
      this.analytics = undefined
      if (this.cookieConsent) {
        console.debug("initializing analytics now")
        this.analytics = this.firebase.analytics()
      } else {
        this.analytics = undefined
      }
      console.debug(`initializing Firebase now.`)
      resolve(this)
    })
  }

  setCookieConsent = cc => {
    this.cookieConsent = cc
    if (cc === true && this.analytics === undefined) {
      console.debug("initializing analytics now")
      this.analytics = this.firebase.analytics()
    }
  }

  logEvent = event => {
    if (this.analytics) {
      this.analytics.logEvent(event)
    } else if (this.cookieConsent !== true) {
      console.warn("no cookie consent, no analytics")
    } else {
      console.warn("cannot log analytics")
    }
  }
}

export default Firebase
