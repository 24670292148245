import React, { useState, createContext, useContext, useEffect } from "react"
import Cookies from "js-cookie"
import CookieBanner from "./Banner"
import Layout from "../../components/common/layout/layout"

const ConsentContext = createContext({ consent: false })
const COOKIE_NAME = "consent_cookie"

export const ConsentProvider = ({ children }) => {
  const [consent, setConsent] = useState(undefined)
  let consentCookie = Cookies.get(COOKIE_NAME)

  useEffect(() => {
    if (consentCookie !== undefined) {
      setConsent(consentCookie === "true")
    } else {
      setConsent(undefined)
    }
  }, [consentCookie])

  useEffect(() => {
    if (consentCookie === undefined && consent !== undefined) {
      Cookies.set(COOKIE_NAME, consent, { expires: 30 })
    }
  }, [consent, consentCookie])

  return (
    <ConsentContext.Provider value={consent}>
      {children}
      {consent === undefined && (
        <Layout>
          <CookieBanner setConsent={setConsent} />
        </Layout>
      )}
    </ConsentContext.Provider>
  )
}

const useConsent = () => useContext(ConsentContext)

export default useConsent
